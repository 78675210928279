export default {
    data() {
        return {
            loading: false,
            finished: false,
            list: [],
            pagination: {
                page: 1,
                pageSize: 20,
            },

        };
    },
    methods: {
    },
};